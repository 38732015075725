<template>
  <div class="staffList">
    <!-- 员工列表 -->
    <div v-show="!showBussinessList">
      <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
        <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
          <el-form
            :inline="true"
            :model="form"
            class="demo-form-inline"
            style="width: 100%"
          >
            <el-form-item label="分公司编号">
              <el-input
                v-model="form.spreadId"
                placeholder="请输入分公司编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="分公司名称">
              <el-input
                v-model="form.queryTag"
                placeholder="请输入分公司名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-button">
              <el-button type="primary" @click="search('search')"
                >筛选</el-button
              >
              <el-button type="info" @click="clear">清空</el-button>
               <el-button type="primary" @click="add"
                >新增公司</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="tableBox">
        <el-table :data="tableData" style="width: 100%" border>
          <el-table-column prop="spreadId" label="分公司编号"></el-table-column>
          <el-table-column prop="platName" label="分公司名称"  width="200px"></el-table-column>
          <el-table-column
            prop="tel"
            label="账号"
            width="160px"
          ></el-table-column>
          <el-table-column prop="profitPercent" label="分润比例"></el-table-column>
          <el-table-column prop="profitAmount" label="总分润"></el-table-column>
          <el-table-column prop="withdraws" label="已提现"></el-table-column>
          <el-table-column prop="canWithdraws" label="可提现"></el-table-column>
          <el-table-column prop="shopNum" label="店铺"></el-table-column>
          <el-table-column prop="orderNum" label="订单"></el-table-column>
          <el-table-column prop="staffNum" label="员工"></el-table-column>
          <el-table-column prop="agentNum" label="代理"></el-table-column>
          <el-table-column prop="" label="操作" align="center" width="100px">
            <template slot-scope="scope">
              <el-button
                type="warning"
                plain
                size="mini"
                @click="switchBussinessList(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        class="mt10"
        background
        :page-count="totalPage"
        :current-page.sync="currentPage"
        @current-change="pageChange"
      >
      </el-pagination>
      <!-- 新增/编辑员工弹窗 -->

    </div>
    <!-- 商家列表 -->
    <div v-show="showBussinessList">
      <el-button type="primary" plain @click="switchStaffList"
        >返回上级</el-button
      >
      <div class="tableBox">
        <el-table :data="bussinessTableData" style="width: 100%" border>
          <el-table-column prop="shopName" label="商户昵称"></el-table-column>
          <el-table-column prop="contactTel" label="联系电话"></el-table-column>
          <el-table-column prop="shopLabel" label="店铺标签"></el-table-column>
          <el-table-column prop="" label="操作" width="120"> </el-table-column>
        </el-table>
      </div>
      <el-pagination
        class="mt10"
        background
        :page-count="bussinessTotalPage"
        :current-page.sync="bussinessCurrentPage"
        @current-change="bussinessPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="500px"
      class="dialong-style"
      center
    >
    <p style="padding-bottom:30px">
    <span class="remark-line"></span>
    <span class="remark-title">新增编辑分公司</span>
  </p>
      <el-form ref="form" label-width="80px">

          <el-form-item label="名称">
            <el-input v-model="codeform.platName" placeholder="请输入分公司名称"></el-input>
          </el-form-item>
             <el-form-item label="登录账号">
            <el-input v-model="codeform.tel" :disabled="readState" placeholder="请输入用户手机号作为登录账号"></el-input>
          </el-form-item>
             <el-form-item label="初始密码">
            <el-input v-model="codeform.password"  placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="分润比例">
            <el-input v-model="codeform.scale" :maxlength="10" placeholder="请设置分润比例" type="number"></el-input>
            <span style="padding-left:5px;">%</span>
          </el-form-item>

        </el-form>
         <span slot="footer" class="dialog-footer">

    <el-button type="primary" @click="savebranchItem">保存</el-button>
     <el-button @click="centerDialogVisible = false">取 消</el-button>
  </span>
    </el-dialog>
  </div>
</template>
<script>
import { $sp_spreadList } from '@/api/order';
// import { $sp_agentList } from '@/api/agent';
import {
  $sp_addBranchItem,
  $sp_branchList,
  $sp_getBranchItem,
  $sp_updateBranchItem,
} from '@/api/staff';

export default {
  data() {
    return {
      form: {
        platName: '',
        spreadId: '',
      },
      codeform: {
        platName: '',
        tel: '',
        scale: '',
        password: '',
      },
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      branchOfficeList: [],
      readState: false,

      showBussinessList: false, // 是否显示商家列表
      staffId: '',
      bussinessTotalPage: 1,
      bussinessCurrentPage: 1,
      bussinessTableData: [],
      centerDialogVisible: false,
    };
  },
  created() {
    this.getStaffTableData();
  },
  methods: {
    savebranchItem() {
      if (!this.codeform.platName) {
        this.$message.error('请填写名称');
        return; 
      }
      if (this.codeform.platName.length > 20) {
        this.$message.error('名称20个字以内');
        return; 
      }
      const regular = /^1[3|4|6|5|7|8|9][0-9]\d{8}$/g;
      if (!regular.test(this.codeform.tel)) {
        this.$message.error('请填写正确手机号');
        return; 
      }
      // const regularPsw = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\\\W]{6,18}$/g;
      // if (!regularPsw.test(this.codeform.password)) {
      //   this.$message.error('密码必须是数字和字母组合，在6~18位之间的且不含特殊字符');
      //   return; 
      // }
      if (this.codeform.scale > 100 || this.codeform.scale < 0) {
        this.$message.error('分润比列0~100之间');
        return; 
      }
      const scaleRugalur = /^\d{0,3}$/g;
      if (!scaleRugalur.test(this.codeform.scale)) {
        this.$message.error('请输入正整数');
        return; 
      }
      if (this.readState) {
        $sp_updateBranchItem(this.codeform).then((res) => {
          this.centerDialogVisible = false;
          this.getStaffTableData();
          console.log(res, '22344');
        });
      } else {
        $sp_addBranchItem(this.codeform).then((res) => {
          this.centerDialogVisible = false;
          this.getStaffTableData();
          console.log(res, '22344');
        });
      }
    },
    add() {
      this.readState = false;
      this.centerDialogVisible = true;
      this.codeform = {
        platName: '',
        tel: '',
        scale: '',
        password: '',
      };
    },
    // 筛选
    search() {
      this.currentPage = 1;
      this.getStaffTableData(this.form);
    },
    clear() {
      this.currentPage = 1;
      this.form = {
        queryTag: '',
        spreadId: '',
      };
      this.getStaffTableData();
    },

    bussinessPageChange() {
      this.getStaffTableData();
    },
    // 请求员工列表数据
    getStaffTableData(data = {}) {
      const params = {
        ...data,
        pageCurrent: this.currentPage,
        pageSize: 20,
      };
      $sp_branchList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 搜索归属分公司
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.reviewedShopPage(query);
      } else {
        await this.reviewedShopPage('');
      }
      this.loading = false;
    },
    // 请求归属分公司数据
    reviewedShopPage(platName = '') {
      const params = {
        platName,
      };
      return $sp_spreadList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        this.branchOfficeList = tempArr;
      });
    },
    // 请求代理商列表
    // getAgentList() {
    //   this.form.agentSid = '';
    //   this.agentList = [];
    //   if (!this.form.spreadId) return;
    //   const params = {
    //     pageCurrent: 1,
    //     pageSize: 20,
    //     spreadId: this.form.spreadId,
    //   };
    //   $sp_agentList(params).then((res) => {
    //     const tempArr = [];
    //     res.records.forEach((item) => {
    //       const json = {
    //         value: item.agentSid,
    //         label: item.agentName,
    //       };
    //       tempArr.push(json);
    //     });
    //     this.agentList = tempArr;
    //   });
    // },

    // 打开新增/编辑员工弹窗
    openEditStaffPop(staffId) {
      if (staffId) {
        // 编辑员工
        this.getStaffDetail(staffId);
      } else {
        // 新增员工
        this.currentStaff = {
          spreadId: '',
          phone: '',
          name: '',
        };
      }
      this.editStaffPop = true;
    },
    // 查看员工详情


    // 删除员工

    // 员工列表翻页
    pageChange() {
      this.getStaffTableData();
    },
    // 商家列表中返回上级-显示员工列表
    switchStaffList() {
      this.showBussinessList = false;
    },
    // 显示商家列表
    switchBussinessList(item) {
      this.centerDialogVisible = true;
      $sp_getBranchItem({ id: item.id, spreadId: item.spreadId }).then((res) => {
        this.readState = true;
        this.codeform = res;
      });
    },

  },
  // 移除商家

};
</script>
<style lang="scss" scoped>
 .dialong-style{
    /deep/.el-dialog__body{
      padding: 25px 50px 30px  !important;
    }
     /deep/.el-input{
              width: 90%!important;
    }
    .remark-line{
    display: inline-block;
    height: 20px;
    width: 4px;
    border-radius: 4px;
    background: red;
    }
    .remark-title{
       display: inline-block;
       vertical-align:3px;
       font-size: 18px;
       font-weight: bold;
       padding-left:5px;
    }
   .unploadButton {
    width: 150px;
    height: 32px;
    border: 1px solid #ef3f46;
    border-radius: 4px;
    height: 32px;
    background: #ef3f46;
    border-radius: 4px;
    color: #FFFFFF;
    line-height: 32px;
    text-align: center;
    margin-top: 10px;
  }
  }
.staffList {
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .timeChoose {
    margin-right: 8px;
  }
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table {
    min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
}
</style>
