import api from './api';

// 查询员工列表
export const $sg_staffList = (params) => api.get('/j/platform/staff/query', params);

// 查询员工详情
export const $sg_getStaffDetail = (params) => api.get(`/j/platform/staff/getTStaffInfo/${params}`);

// 新增员工
export const $sp_staffAdd = (params) => api.post('/j/platform/staff/add', params);

// 修改员工
export const $sp_staffUpdate = (params) => api.post('/j/platform/staff/update', params);

// 删除员工
export const $sp_staffDelete = (params) => api.post(`/j/platform/staff/delete/${params}`);

// 查询商户列表
export const $sg_queryShopByStaffId = (params) => api.get('/j/platform/staff_mch/queryShopByStaffId', params);

// 移除商户
export const $sp_deleteShop = (params) => api.post(`/j/platform/staff_mch/delete/${params}`);

// 分公司列表
export const $sp_branchList = (params) => api.get('/j/platform/t-spread-info/spread/list', params);
// 新增分公司
export const $sp_addBranchItem = (params) => api.post('/j/platform/t-spread-user/add', params);
// 编辑公司
export const $sp_getBranchItem = (params) => api.get('/j/platform/t-spread-user/get', params);
// 更新公司
export const $sp_updateBranchItem = (params) => api.post('/j/platform/t-spread-user/update', params);
